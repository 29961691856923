import React from 'react';
import Layout from '../../../components/Layout';
import Seo from '../../../components/SEO';
import SectionIntro from '../../../components/SectionIntro';

// Background images
import {convertToBgImage} from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import {graphql, useStaticQuery} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';

const Privacy = () => {
  const dataImages = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: {eq: "bg/rebrand/bg-main-top-right.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);

  const {backgroundImage} = dataImages;

  const bgImage = convertToBgImage(getImage(backgroundImage));

  return (
    <Layout seo={<Seo title="Privacy Policy | Trustle" description="Legal Documents" />}>
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        className="bg-cover"
      >
        <div className="max-w-[42rem] mx-auto pl-5 md:pl-0">
          <section className="pt-16 pb-8 mb-8 ">
            <div className="max-w-5xl px-5 pt-0 mx-auto text-center text-white">
              <SectionIntro title="Privacy Policy" isPageHeader />
            </div>
          </section>
        </div>
      </BackgroundImage>
      <section className="relative py-16 ">
        <div className="max-w-[1360px] mx-auto text-lg opacity-80">
          <div className="mx-5 text-black lg:mx-20 ">
            <h1 className="text-lg font-bold text-center">Privacy Policy</h1>
            <p>Effective date: October 10, 2021</p>
            <br></br>
            <p>
              Please read this Privacy Policy to learn how we treat your personal data.{' '}
              <b>
                By using or accessing our Services in any manner, you acknowledge that you accept the practices and
                policies outlined below, and you hereby consent that we will collect, use and share your information as
                described in this Privacy Policy.
              </b>
            </p>
            <br></br>
            <p>
              Remember that your use of Trustle's Services is at all times subject to our Terms of Use
              https://trustle.com/legal/, which incorporates this Privacy Policy. Any terms we use in this Policy
              without defining them have the definitions given to them in the Terms of Use.
            </p>{' '}
            <br></br>
            <p>You may print a copy of this Privacy Policy.</p>
            <br></br>
            <h2 className="font-bold ">Privacy Policy Table of Contents</h2>
            <br></br>
            <ul className="mb-2 ml-10">
              <li className="font-bold">
                <a href="#WhatthisPrivacyPolicyCovers" className="hover:underline">
                  What this Privacy Policy Covers
                </a>
              </li>
              <li className="font-bold">
                <a href="#PersonalData" className="hover:underline">
                  Personal Data
                </a>
              </li>
              <ul className="ml-10">
                <li>
                  <p>Categories of Personal Data We Collect</p>
                </li>
                <li>
                  <p>Categories of Sources of Personal Data</p>
                </li>
                <li>
                  <p>Our Commercial or Business Purposes for Collecting Personal Data</p>
                </li>
              </ul>
              <li className="font-bold">
                <a href="#HowWeShareYourPersonalData" className="hover:underline">
                  How We Share Your Personal Data
                </a>
              </li>
              <li className="font-bold">
                <a href="#TrackingToolsAdvertisingandOptOut" className="hover:underline">
                  Tracking Tools, Advertising and Opt-Out
                </a>
              </li>
              <li className="font-bold">
                <a href="#DataSecurityandRetention" className="hover:underline">
                  Data Security and Retention
                </a>
              </li>
              <li className="font-bold">
                <a href="#PersonalDataofChildren" className="hover:underline">
                  Personal Data of Children
                </a>
              </li>
              <li className="font-bold">
                <a href="#CaliforniaResidentRights" className="hover:underline">
                  California Resident Rights
                </a>
              </li>
              <li className="font-bold">
                <a href="#OtherStateLawPrivacyRights" className="hover:underline">
                  Other State Law Privacy Rights
                </a>
              </li>
              <li className="font-bold">
                <a href="#EuropeanUnionDataSubjectRights" className="hover:underline">
                  European Union Data Subject Rights
                </a>
              </li>
              <li className="font-bold">
                <a href="#ChangestothisPrivacyPolicy" className="hover:underline">
                  Changes to this Privacy Policy
                </a>
              </li>

              <li className="font-bold ">
                <a href="#googleAPIServicesUserDataPolicy" className="hover:underline">
                  Google API Services User Data Policy
                </a>
              </li>
              <li className="font-bold ">
                <a href="#contacInformation" className="hover:underline">
                  Contact Information
                </a>
              </li>
            </ul>
            <br></br>
            <h2 className="mb-2 font-bold underline" id="WhatthisPrivacyPolicyCovers">
              What this Privacy Policy Covers
            </h2>
            <p>
              This Privacy Policy covers how we treat Personal Data that we gather when you access or use our Services.
              “Personal Data” means any information that identifies or relates to a particular individual and also
              includes information referred to as “personally identifiable information” or “personal information” under
              applicable data privacy laws, rules or regulations. This Privacy Policy does not cover the practices of
              companies we don’t own or control or people we don’t manage.
            </p>
            <br></br>
            <h2 className="mb-2 font-bold underline" id="PersonalData">
              Personal Data
            </h2>
            <p className="mb-2 italic underline">Categories of Personal Data We Collect</p>
            <p>
              This chart details the categories of Personal Data that we collect and have collected over the past 12
              months:
            </p>
            <p>
              <br></br>
            </p>
            <table className="border border-gray-200 table-fixed">
              <thead>
                <tr className="text-center bg-gray-200 border border-gray-200 ">
                  <td className="w-24 p-4 md:w-64">
                    <p className="font-bold underline">Category of Personal Data</p>
                  </td>
                  <td className="p-4">
                    <p className="font-bold underline">Examples of Personal Data We Collect</p>
                  </td>
                  <td className="p-4">
                    <p className="font-bold underline">
                      Categories of Third Parties With Whom We Share this Personal Data:
                    </p>
                  </td>
                </tr>
              </thead>
              <tbody className="border border-gray-200">
                <tr className="border border-gray-200">
                  <td className="p-4">
                    <p className="text-sm font-bold md:text-lg">Profile or Contact Data</p>
                  </td>
                  <td className="p-4 border border-gray-200 ">
                    <ul className="ml-5 text-sm list-disc">
                      <li>First and last name</li>
                      <li>Email</li>
                      <li>Phone number</li>
                      <li>Unique identifiers such as account name and passwords</li>
                    </ul>
                  </td>
                  <td className="p-4">
                    <ul className="ml-5 text-sm list-disc">
                      <li>Service Providers</li>
                      <li>Parties You Authorize, Access or Authenticate</li>
                    </ul>
                  </td>
                </tr>
                <tr className="border border-gray-200">
                  <td className="p-4">
                    <p className="text-sm font-bold md:text-lg">Payment Data</p>
                  </td>
                  <td className="p-4 border border-gray-200 ">
                    <ul className="ml-5 text-sm list-disc">
                      <li>Payment card type</li>
                      <li>Last 4 digits of payment card</li>
                      <li>Billing address, phone number, and email</li>
                    </ul>
                  </td>
                  <td className="p-4">
                    <ul className="ml-5 text-sm list-disc">
                      <li>Service Providers (specifically our payment processing partner, currently Stripe, Inc.)</li>
                    </ul>
                  </td>
                </tr>
                <tr className="border border-gray-200">
                  <td className="p-4">
                    <p className="text-sm font-bold md:text-lg">Device/IP Data</p>
                  </td>
                  <td className="p-4 border border-gray-200 ">
                    <ul className="ml-5 text-sm list-disc">
                      <li>IP address</li>
                      <li>Type of device/operating system/browser used to access the Services</li>
                    </ul>
                  </td>
                  <td className="p-4">
                    <ul className="ml-5 text-sm list-disc">
                      <li>Service Providers</li>
                      <li>Analytics Partners</li>
                    </ul>
                  </td>
                </tr>
                <tr className="border border-gray-200">
                  <td className="p-4">
                    <p className="text-sm font-bold md:text-lg">Web Analytics</p>
                  </td>
                  <td className="p-4 border border-gray-200 ">
                    <ul className="ml-5 text-sm list-disc">
                      <li>Web page interactions</li>
                      <li>Referring webpage/source through which you accessed the Services</li>
                      <li>Statistics associated with the interaction between device or browser and the Services</li>
                    </ul>
                  </td>
                  <td className="p-4 ">
                    <ul className="ml-5 text-sm list-disc">
                      <li>Service Providers</li>
                      <li>Analytics Partners</li>
                    </ul>
                  </td>
                </tr>
                <tr className="border border-gray-200">
                  <td className="p-4">
                    <p className="text-sm font-bold md:text-lg">Geolocation Data</p>
                  </td>
                  <td className="p-4 border border-gray-200 ">
                    <ul className="ml-5 text-sm list-disc">
                      <li>IP-address-based location information</li>
                    </ul>
                  </td>
                  <td className="p-4">
                    <ul className="ml-5 text-sm list-disc">
                      <li>Service Providers</li>
                      <li>Analytics Partners</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <br></br>
            </p>
            <p className="mb-2 italic underline">Categories of Sources of Personal Data</p>
            <p>We collect Personal Data about you from the following categories of sources:</p>
            <ul className="ml-10">
              <li className="font-bold list-disc">You</li>
              <ul className="mb-2 ml-10 ">
                <li className="underline">When you provide such information directly to us.</li>
                <ul className="ml-10 list-disc">
                  <li>When you create an account or use our interactive tools and Services.</li>
                  <li>
                    When you voluntarily provide information in free-form text boxes through the Services or through
                    responses to surveys or questionnaires.
                  </li>
                  <li>When you send us an email or otherwise contact us.</li>
                </ul>

                <li className="underline">
                  When you use the Services and such information is collected automatically.
                </li>
                <ul className="ml-10 list-disc">
                  <li>Through Cookies (defined in the “Tracking Tools, Advertising and Opt-Out” section below).</li>
                  <li>If you use a location-enabled browser, we may receive information about your location.</li>
                  <li>
                    If you download and install certain applications and software we make available, we may receive and
                    collect information transmitted from your computing device for the purpose of providing you the
                    relevant Services, such as information regarding when you are logged on and available to receive
                    updates or alert notices.
                  </li>
                </ul>
              </ul>

              <li className="font-bold list-disc ">Third Parties</li>
              <ul className="mb-2 ml-10 ">
                <li className="underline">Vendors</li>
                <ul className="ml-10 list-disc">
                  <li>
                    <p>
                      We may use analytics providers to analyze how you interact and engage with the Services, or third
                      parties may help us provide you with customer support.
                    </p>
                  </li>
                  <li>
                    <p>We may use vendors to obtain information to generate leads and create user profiles.</p>
                  </li>
                </ul>

                <li className="underline">Advertising Partners</li>
                <ul className="ml-10 list-disc">
                  <li>
                    We receive information about you from some of our vendors who assist us with marketing or
                    promotional services related to how you interact with our websites, applications, products,
                    Services, advertisements or communications.
                  </li>
                </ul>

                <li className="underline">Social Networks</li>
                <ul className="ml-10 list-disc">
                  <li>
                    If you provide your social network account credentials to us or otherwise sign in to the Services
                    through a third-party site or service, some content and/or information in those accounts may be
                    transmitted into your account with us.
                  </li>
                </ul>
              </ul>
            </ul>
            <br></br>
            <p className="mb-2 italic underline">Our Commercial or Business Purposes for Collecting Personal Data</p>
            <ul className="ml-10">
              <li className="font-bold list-disc">Providing, Customizing and Improving the Services</li>
              <ul className="mb-2 ml-10 list-disc">
                <li>Creating and managing your account or other user profiles.</li>
                <li>Providing you with the products, services or information you request.</li>
                <li>Meeting or fulfilling the reason you provided the information to us.</li>
                <li>Providing support and assistance for the Services.</li>
                <li>
                  Improving the Services, including testing, research, internal analytics and product development.
                </li>
                <li>Personalizing the Services, website content and communications based on your preferences.</li>
                <li>Doing fraud protection, security and debugging.</li>
                <li>
                  Carrying out other business purposes stated when collecting your Personal Data or as otherwise set
                  forth in applicable data privacy laws, such as the California Consumer Privacy Act (the “CCPA”).
                </li>
              </ul>

              <li className="font-bold list-disc">Marketing the Services </li>
              <ul className="mb-2 ml-10 list-disc">
                <li>Marketing and selling the Services.</li>
              </ul>

              <li className="font-bold list-disc">Corresponding with You</li>
              <ul className="mb-2 ml-10 list-disc">
                <li>
                  Responding to correspondence that we receive from you, contacting you when necessary or requested, and
                  sending you information about Trustle or the Services.
                </li>
                <li>
                  Sending emails and other communications according to your preferences or that display content that we
                  think will interest you.
                </li>
              </ul>

              <li className="font-bold list-disc">Meeting Legal Requirements and Enforcing Legal Terms</li>
              <ul className="mb-2 ml-10 list-disc">
                <li>
                  Fulfilling our legal obligations under applicable law, regulation, court order or other legal process,
                  such as preventing, detecting and investigating security incidents and potentially illegal or
                  prohibited activities.
                </li>
                <li>Protecting the rights, property or safety of you, Trustle or another party.</li>
                <li>Enforcing any agreements with you.</li>
                <li>Responding to claims that any posting or other content violates third-party rights.</li>
                <li>Resolving disputes.</li>
              </ul>
            </ul>
            <p>
              We will not collect additional categories of Personal Data or use the Personal Data we collected for
              materially different, unrelated or incompatible purposes without providing you notice.
            </p>
            <p>
              <br></br>
            </p>
            <h2 className="my-2 font-bold underline" id="HowWeShareYourPersonalData">
              How We Share Your Personal Data
            </h2>
            <p>
              We disclose your Personal Data to the categories of service providers and other parties listed in this
              section. Depending on state laws that may be applicable to you, some of these disclosures may constitute a
              “sale” of your Personal Data. For more information, please refer to the state-specific sections below.
            </p>
            <ul className="mb-2 ml-10 list-disc">
              <li>
                <b>Service Providers.</b> These parties help us provide the Services or perform business functions on
                our behalf. They include:
              </li>

              <ul className="mb-2 ml-10 list-disc">
                <li>Hosting, technology and communication providers.</li>
                <li>Security and fraud prevention consultants.</li>
                <li>Support and customer service vendors.</li>
                <li>
                  Payment processors.
                  <ul className="mb-2 ml-10 list-disc">
                    <li>
                      Our payment processing partner Stripe, Inc. (“Stripe”) collects your voluntarily- provided payment
                      card information necessary to process your payment.
                    </li>
                    <li>
                      Please see Stripe’s terms of service and privacy policy for information on its use and storage
                    </li>
                  </ul>
                </li>
              </ul>

              <li>
                <b>Advertising Partners.</b> These parties help us market our services and provide you with other offers
                that may be of interest to you. They include:
                <ul className="mb-2 ml-10 list-disc">
                  <li>Ad networks.</li>
                  <li>Marketing providers.</li>
                </ul>
              </li>
              <li>
                <b> Analytics Partners. </b>
                These parties provide analytics on web traffic or usage of the Services. They include:
                <ul className="mb-2 ml-10 list-disc">
                  <li>Companies that track how users found or were referred to the Services.</li>
                  <li>Companies that track how users interact with the Services.</li>
                </ul>
              </li>
              <li className="font-bold">Parties You Authorize, Access or Authenticate</li>
              <ul className="mb-2 ml-10 list-disc">
                <li>Third parties you access through the services</li>
              </ul>
            </ul>
            <br></br>
            <p className="mb-2 italic underline">Legal Obligations</p>
            <p>
              We may share any Personal Data that we collect with third parties in conjunction with any of the
              activities set forth under “Meeting Legal Requirements and Enforcing Legal Terms” in the “Our Commercial
              or Business Purposes for Collecting Personal Data” section above.
            </p>
            <br></br>
            <p className="mb-2 italic underline">Business Transfers</p>
            <p>
              All of your Personal Data that we collect may be transferred to a third party if we undergo a merger,
              acquisition, bankruptcy or other transaction in which that third party assumes control of our business (in
              whole or in part). Should one of these events occur, we will make reasonable efforts to notify you before
              your information becomes subject to different privacy and security policies and practices.
            </p>
            <br></br>
            <p className="mb-2 italic underline">Data that is Not Personal Data</p>
            <p>
              We may create aggregated, de-identified or anonymized data from the Personal Data we collect, including by
              removing information that makes the data personally identifiable to a particular user. We may use such
              aggregated, de-identified or anonymized data and share it with third parties for our lawful business
              purposes, including to analyze, build and improve the Services and promote our business, provided that we
              will not share such data in a manner that could identify you.
            </p>
            <br></br>
            <h2 className="mb-2 font-bold underline" id="TrackingToolsAdvertisingandOptOut">
              Tracking Tools, Advertising and Opt-Out
            </h2>
            <p>
              The Services use cookies and similar technologies such as pixel tags, web beacons, clear GIFs and
              JavaScript (collectively, “Cookies”) to enable our servers to recognize your web browser, tell us how and
            </p>
            <p>
              when you visit and use our Services, analyze trends, learn about our user base and operate and improve our
              Services. Cookies are small pieces of data– usually text files – placed on your computer, tablet, phone or
              similar device when you use that device to access our Services. We may also supplement the information we
              collect from you with information received from third parties, including third parties that have placed
              their own Cookies on your device(s). Please note that because of our use of Cookies, the Services do not
              support “Do Not Track” requests sent from a browser at this time.
            </p>
            <br></br>
            <p>We use the following types of Cookies:</p>
            <br></br>
            <ul className="mb-2 ml-10 list-disc">
              <li>
                <u>Essential Cookies</u>. Essential Cookies are required for providing you with features or services
                that you have requested. For example, certain Cookies enable you to log into secure areas of our
                Services. Disabling these Cookies may make certain features and services unavailable.
              </li>
              <li>
                <u>Functional Cookies</u>. Functional Cookies are used to record your choices and settings regarding our
                Services, maintain your preferences over time and recognize you when you return to our Services. These
                Cookies help us to personalize our content for you, greet you by name and remember your preferences (for
                example, your choice of language or region).
              </li>
              <li>
                <u>Performance/Analytical Cookies</u>
                <a href="http://www.google.com/privacy_ads.html" target="_blank" rel="noreferrer">
                  . Performance/Analytical Cookies allow us to understand how visitors use our Services. They do this by
                  collecting information about the number of visitors to the Services, what pages visitors view on our
                  Services and how long visitors are viewing pages on the Services. Performance/Analytical Cookies also
                  help us measure the performance of our advertising campaigns in order to help us improve our campaigns
                  and the Services’ content for those who engage with our advertising. For example, Google LLC
                  (“Google”) uses cookies in connection with its Google Analytics services. Google’s ability to use and
                  share information collected by Google Analytics about your visits to the Services is subject to the
                  Google Analytics Terms of Use and the Google Privacy Policy. You have the option to opt-out of
                  Google’s use of Cookies by visiting the Google advertising opt-out page at{' '}
                </a>
                www.google.com/privacy_ads.html or the Google Analytics Opt-out Browser Add-on at
                https://tools.google.com/dlpage/gaoptout/.
              </li>
              <li>
                <p>
                  <u>Retargeting/Advertising Cookies</u>. Retargeting/Advertising Cookies collect data about your online
                  activity and identify your interests so that we can provide advertising that we believe is relevant to
                  you. For more information about this, please see the section below titled “Information about Interest-
                  Based Advertisements.”
                </p>
              </li>
            </ul>
            <p>
              You can decide whether or not to accept Cookies through your internet browser’s settings. Most browsers
              have an option for turning off the Cookie feature, which will prevent your browser from accepting new
              Cookies, as well as (depending on the sophistication of your browser software) allow you to decide on
              acceptance of each new Cookie in a variety of ways. You can also delete all Cookies that are already on
              your device. If you do this, however, you may have to manually adjust some preferences every time you
              visit our website and some of the Services and functionalities may not work.
            </p>
            <p>
              <br></br>
            </p>
            <p>
              <a href="http://www.allaboutcookies.org/" target="_blank" rel="noreferrer">
                To explore what Cookie settings are available to you, look in the “preferences” or “options” section of
                your browser’s menu. To find out more information about Cookies, including information about how to
                manage and delete Cookies, please visit{' '}
              </a>
              http://www.allaboutcookies.org/ or https://ico.org.uk/for-the- public/online/cookies/ if you are located
              in the European Union.
            </p>
            <p>
              <br></br>
            </p>
            <p className="italic underline">Information about Interest-Based Advertisements:</p>
            <p>
              We may serve advertisements, and also allow third-party ad networks, including third-party ad servers, ad
              agencies, ad technology vendors and research firms, to serve advertisements through the Services. These
              advertisements may be targeted to users who fit certain general profile categories or display certain
              preferences or behaviors (“Interest-Based Ads”). Information for Interest-Based Ads (including Personal
              Data) may be provided to us by you, or derived from the usage patterns of particular users on the Services
              and/or services of third parties. Such information may be gathered through tracking users’ activities
              across time and unaffiliated properties, including when you leave the Services. To accomplish this, we or
              our service providers may deliver Cookies, including a file (known as a “web beacon”) from an ad network
              to you through the Services. Web beacons allow ad networks to provide anonymized, aggregated auditing,
              research and reporting for us and for advertisers. Web beacons also enable ad networks to serve targeted
              advertisements to you when you visit other websites. Web beacons allow ad networks to view, edit or set
              their own Cookies on your browser, just as if you had requested a web page from their site.
            </p>
            <br></br>
            <p>
              We comply with the Digital Advertising Alliance (“DAA”) Self-Regulatory Principles for Online Behavioral
              Advertising. Through the DAA and Network Advertising Initiative (“NAI”), several media and marketing
              associations have developed an industry self-regulatory program to give consumers a better understanding
              of, and greater control over, ads that are customized based a consumer’s online behavior across different
              websites and properties. To make choices about Interest-Based Ads from participating third parties,
              including to opt-out of receiving behaviorally targeted advertisements from participating organizations,
              please visit the DAA’s or NAI’s consumer opt-out pages, which are located at{' '}
              <a href="http://www.aboutads.info/choices" target="_blank" rel="noreferrer">
                http://www.networkadvertising.org/choices/ or{' '}
              </a>
              <a href="http://www.youronlinechoices.eu/" target="_blank" rel="noreferrer">
                www.aboutads.info/choices. Users in the European Union should visit the European Interactive Digital
                Advertising Alliance’s user information website{' '}
              </a>
              <a href="http://www.youronlinechoices.eu/" target="_blank" rel="noreferrer">
                http://www.youronlinechoices.eu/.
              </a>
            </p>
            <br></br>
            <h2 className="my-2 font-bold underline" id="DataSecurityandRetention">
              Data Security and Retention
            </h2>
            <p>
              We seek to protect your Personal Data from unauthorized access, use and disclosure using appropriate
              physical, technical, organizational and administrative security measures based on the type of Personal
              Data and how we are processing that data. You should also help protect your data by appropriately
              selecting and protecting your password and/or other sign-on mechanism; limiting access to your computer or
              device and browser; and signing off after you have finished accessing your account. Although we work to
              protect the security of your account and other data that we hold in our records, please be aware that no
              method of transmitting data over the internet or storing data is completely secure.
            </p>
            <p>
              We retain Personal Data about you for as long as you have an open account with us or as otherwise
              necessary to provide you with our Services. In some cases we retain Personal Data for longer, if doing so
              is necessary to comply with our legal obligations, resolve disputes or collect fees owed, or is otherwise
              permitted or required by applicable law, rule or regulation. We may further retain information in an
              anonymous or aggregated form where that information would not identify you personally.
            </p>
            <br></br>
            <h2 className="my-2 font-bold underline" id="PersonalDataofChildren">
              Personal Data of Children
            </h2>
            <p>
              As noted in the Terms of Use, we do not knowingly collect or solicit Personal Data about children under 16
              years of age; if you are a child under the age of 16, please do not attempt to register for or otherwise
              use the Services or send us any Personal Data. If we learn we have collected Personal Data from a child
              under 16 years of age, we will delete that information as quickly as possible. If you believe that a child
              under 16 years of age may have provided Personal Data to us, please contact us at [SUPPORT EMAIL].
            </p>
            <br></br>
            <h2 className="my-2 font-bold underline" id="CaliforniaResidentRights">
              California Resident Rights
            </h2>
            <p>
              If you are a California resident, you have the rights set forth in this section. Please see the
              “Exercising Your Rights” section below for instructions regarding how to exercise these rights. Please
              note that we may process Personal Data of our customers’ end users or employees in connection with our
              provision of certain services to our customers. If we are processing your Personal Data as a service
              provider, you should contact the entity that collected your Personal Data in the first instance to address
              your rights with respect to such data.
            </p>
            <p>
              If there are any conflicts between this section and any other provision of this Privacy Policy and you are
              a California resident, the portion that is more protective of Personal Data shall control to the extent of
              such conflict. If you have any questions about this section or whether any of the following rights apply
              to you, please contact us at [SUPPORT EMAIL].
            </p>
            <br></br>
            <p className="italic underline">Access</p>
            <p>
              You have the right to request certain information about our collection and use of your Personal Data over
              the past 12 months. In response, we will provide you with the following information:
            </p>
            <ul className="mb-2 ml-10 list-disc">
              <li>The categories of Personal Data that we have collected about you.</li>
              <li>The categories of sources from which that Personal Data was collected.</li>
              <li>The business or commercial purpose for collecting or selling your Personal Data.</li>
              <li>The categories of third parties with whom we have shared your Personal Data.</li>
              <li>The specific pieces of Personal Data that we have collected about you.</li>
            </ul>
            <p>
              If we have disclosed your Personal Data to any third parties for a business purpose over the past 12
              months, we will identify the categories of Personal Data shared with each category of third party
              recipient. If we have sold your Personal Data over the past 12 months, we will identify the categories of
              Personal Data sold to each category of third party recipient.
            </p>
            <br></br>
            <p className="italic underline">Deletion</p>
            <p>
              You have the right to request that we delete the Personal Data that we have collected about you. Under the
              CCPA, this right is subject to certain exceptions: for example, we may need to retain your Personal Data
              to provide you with the Services or complete a transaction or other action you have requested. If your
              deletion request is subject to one of these exceptions, we may deny your deletion request.
            </p>
            <br></br>
            <p className="italic underline">Exercising Your Rights</p>
            <p>
              To exercise the rights described above, you or your Authorized Agent (defined below) must send us a
              request that (1) provides sufficient information to allow us to verify that you are the person about whom
              we have collected Personal Data, and (2) describes your request in sufficient detail to allow us to
              understand, evaluate and respond to it. Each request that meets both of these criteria will be considered
              a “Valid Request.” We may not respond to requests that do not meet these criteria. We will only use
              Personal Data provided in a Valid Request to verify your identity and complete your request. You do not
              need an account to submit a Valid Request.
            </p>
            <br></br>
            <p>
              We will work to respond to your Valid Request within 45 days of receipt. We will not charge you a fee for
              making a Valid Request unless your Valid Request(s) is excessive, repetitive or manifestly unfounded. If
            </p>
            <p>
              we determine that your Valid Request warrants a fee, we will notify you of the fee and explain that
              decision before completing your request.
            </p>
            <br></br>
            <p>You may submit a Valid Request using the following methods:</p>
            <ul className="mb-2 ml-10 list-disc">
              <li>
                <p>
                  <a href="mailto:legal@trustle.com" target="_blank" rel="noreferrer">
                    Email us{' '}
                  </a>
                  <a href="mailto:legal@trustle.com" target="_blank" rel="noreferrer">
                    at:legal@trustle.com
                  </a>
                </p>
              </li>
            </ul>
            <p>
              You may also authorize an agent (an “Authorized Agent”) to exercise your rights on your behalf. To do
              this, you must provide your Authorized Agent with written permission to exercise your rights on your
              behalf, and we may request a copy of this written permission from your Authorized Agent when they make a
              request on your behalf.
            </p>
            <br></br>
            <p className="italic underline">Personal Data Sales Opt-Out and Opt-In</p>
            <p>
              We will not sell your Personal Data, and have not done so over the last 12 months. To our knowledge, we do
              not sell the Personal Data of minors under 16 years of age.
            </p>
            <br></br>
            <p className="italic underline">
              We Will Not Discriminate Against You for Exercising Your Rights Under the CCPA
            </p>
            <p>
              We will not discriminate against you for exercising your rights under the CCPA. We will not deny you our
              goods or services, charge you different prices or rates, or provide you a lower quality of goods and
              services if you exercise your rights under the CCPA. However, we may offer different tiers of our Services
              as allowed by applicable data privacy laws (including the CCPA) with varying prices, rates or levels of
              quality of the goods or services you receive related to the value of Personal Data that we receive from
              you.
            </p>
            <br></br>
            <h2 className="my-2 font-bold underline" id="OtherStateLawPrivacyRights">
              Other State Law Privacy Rights
            </h2>
            <p className="italic underline">California Resident Rights</p>
            <p>
              <a href="mailto:legal@trustle.com" target="_blank" rel="noreferrer">
                Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to
                prevent disclosure of Personal Data to third parties for such third parties’ direct marketing purposes;
                in order to submit such a request, please contact us at{' '}
              </a>
              legal@trustle.com. Please note, however, that we do not disclose Personal Data to third parties for such
              third parties’ direct marketing purposes.
            </p>
            <br></br>
            <p className="italic underline">Nevada Resident Rights</p>
            <p>
              <a href="mailto:legal@trustle.com" target="_blank" rel="noreferrer">
                If you are a resident of Nevada, you have the right to opt-out of the sale of certain Personal Data to
                third parties who intend to license or sell that Personal Data. You can exercise this right by
                contacting us at{' '}
              </a>
              legal@trustle.com with the subject line “Nevada Do Not Sell Request” and providing us with your name and
              the email address associated with your account. Please note, however, that we do sell Personal Data to
              third parties who intend to license or sell that Personal Data.
            </p>
            <br></br>
            <h2 className="my-2 font-bold underline" id="EuropeanUnionDataSubjectRights">
              European Union Data Subject Rights
            </h2>
            <p className="italic underline">EU Residents</p>
            <p>
              If you are a resident of the European Union (“EU”), United Kingdom, Lichtenstein, Norway or Iceland, you
              may have additional rights under the EU General Data Protection Regulation (the “GDPR”) with respect to
              your Personal Data, as outlined below.
            </p>
            <p>
              For this section, we use the terms “Personal Data” and “processing” as they are defined in the GDPR, but
              “Personal Data” generally means information that can be used to individually identify a person, and
              “processing” generally covers actions that can be performed in connection with data such as collection,
              use, storage and disclosure. Trustle will be the controller of your Personal Data processed in connection
              with the Services.
            </p>
            <p>
              <a href="mailto:legal@trustle.com" target="_blank" rel="noreferrer">
                If there are any conflicts between this this section and any other provision of this Privacy Policy, the
                policy or portion that is more protective of Personal Data shall control to the extent of such conflict.
                If you have any questions about this section or whether any of the following applies to you, please
                contact us at{' '}
              </a>
              <span>legal@trustle.com. </span>
              <span>Note that we may also process Personal Data of our customers’ end users or</span>
              <span> </span>
              <span>
                employees in connection with our provision of certain services to customers, in which case we are the
              </span>
              <span> </span>
              <span>
                processor of Personal Data. If we are the processor of your Personal Data (i.e., not the controller),
                please
              </span>
              <span> </span>
              <span>
                contact the controller party in the first instance to address your rights with respect to such data.
              </span>
            </p>
            <br></br>
            <p className="italic underline">Personal Data We Collect</p>
            <p>
              The “Categories of Personal Data We Collect” section above details the Personal Data that we collect from
              you.
            </p>
            <br></br>
            <p className="italic underline">Personal Data Use and Processing Grounds</p>
            <p>
              The “Our Commercial or Business Purposes for Collecting Personal Data” section above explains how we use
              your Personal Data.
            </p>
            <br></br>
            <p>
              We will only process your Personal Data if we have a lawful basis for doing so. Lawful bases for
              processing include consent, contractual necessity and our “legitimate interests” or the legitimate
              interest of others, as further described below.
            </p>
            <br></br>
            <ul className="mb-2 ml-10 list-disc">
              <li>
                <u>Contractual Necessity</u>: We process the following categories of Personal Data as a matter of
                “contractual necessity”, meaning that we need to process the data to perform under our Terms of Use with
                you, which enables us to provide you with the Services. When we process data due to contractual
                necessity, failure to provide such Personal Data will result in your inability to use some or all
                portions of the Services that require such data.
                <ul className="mb-2 ml-10 list-disc">
                  <li>Profile or Contact Data</li>
                  <li>Payment Data</li>
                  <li>Device/IP Data</li>
                  <li>Web Analytics</li>
                  <li>Geolocation Data</li>
                </ul>
              </li>
              <li>
                <u>Legitimate Interest</u>: We process the following categories of Personal Data when we believe it
                furthers the legitimate interest of us or third parties:
                <ul className="mb-2 ml-10 list-disc">
                  <li>Profile or Contact Data</li>
                  <li>Payment Data</li>
                  <li>Device/IP Data</li>
                  <li>Web Analytics</li>
                  <li>Geolocation Data</li>
                  <li>We may also de-identify or anonymize Personal Data to further our legitimate interests.</li>
                </ul>
                <p>Examples of these legitimate interests include (as described in more detail above):</p>
                <ul className="mb-2 ml-10 list-disc">
                  <li>
                    <p>Providing, customizing and improving the Services.</p>
                  </li>
                  <li>
                    <p>Marketing the Services.</p>
                  </li>
                  <li>
                    <p>Corresponding with you.</p>
                  </li>
                  <li>
                    <p>Meeting legal requirements and enforcing legal terms.</p>
                  </li>
                  <li>
                    <p>Completing corporate transactions.</p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <u>Consent</u>: In some cases, we process Personal Data based on the consent you expressly grant to us
                  at the time we collect such data. When we process Personal Data based on your consent, it will be
                  expressly indicated to you at the point and time of collection.
                </p>
              </li>
              <li>
                <p>
                  <u>Other Processing Grounds</u>: From time to time we may also need to process Personal Data to comply
                  with a legal obligation, if it is necessary to protect the vital interests of you or other data
                  subjects, or if it is necessary for a task carried out in the public interest.
                </p>
              </li>
            </ul>
            <p className="italic underline">Sharing Personal Data</p>
            <p>
              The “How We Share Your Personal Data” section above details how we share your Personal Data with third
              parties.
            </p>
            <br></br>
            <p className="italic underline">EU Data Subject Rights</p>
            <p>
              <a href="mailto:legal@trustle.com" target="_blank" rel="noreferrer">
                You have certain rights with respect to your Personal Data, including those set forth below. For more
                information about these rights, or to submit a request, please email us at{' '}
              </a>
              legal@trustle.com. Please note that in some circumstances, we may not be able to fully comply with your
              request, such as if it is frivolous or extremely impractical, if it jeopardizes the rights of others, or
              if it is not required by law, but in those circumstances, we will still respond to notify you of such a
              decision. In some cases, we may also need you to provide us with additional information, which may include
              Personal Data, if necessary to verify your identity and the nature of your request.
            </p>
            <ul className="mb-2 ml-10 list-disc">
              <li>
                <b>Access:</b> You can request more information about the Personal Data we hold about you and request a
                copy of such Personal Data. You can also access certain of your Personal Data by logging on to your
                account.
              </li>
              <li>
                <b> Rectification:</b> If you believe that any Personal Data we are holding about you is incorrect or
                incomplete, you can request that we correct or supplement such data. You can also correct some of this
                information directly by logging on to your account.
              </li>
              <li>
                <b>Erasure:</b> You can request that we erase some or all of your Personal Data from our systems.
              </li>
              <li>
                <b>Withdrawal of Consent:</b> If we are processing your Personal Data based on your consent (as
                indicated at the time of collection of such data), you have the right to withdraw your consent at any
                time. Please note, however, that if you exercise this right, you may have to then provide express
                consent on a case-by-case basis for the use or disclosure of certain of your Personal Data, if such use
                or disclosure is necessary to enable you to utilize some or all of our Services.
              </li>
              <li>
                <b>Portability:</b> You can ask for a copy of your Personal Data in a machine-readable format. You can
                also request that we transmit the data to another controller where technically feasible.
              </li>
              <li>
                <b>Objection:</b> You can contact us to let us know that you object to the further use or disclosure of
                your Personal Data for certain purposes, such as for direct marketing purposes.
              </li>
              <li>
                <b>Restriction of Processing:</b> You can ask us to restrict further processing of your Personal Data.
              </li>
              <li>
                <b>Right to File Complaint:</b> You have the right to lodge a complaint about Trustle's practices with
                respect to your Personal Data with the supervisory authority of your country or EU Member State. A list
                of Supervisory Authorities is available here: https://edpb.europa.eu/about-{' '}
                <span>edpb/board/members_en</span>.
              </li>
            </ul>
            <br></br>
            <p className="italic underline">Transfers of Personal Data</p>
            <p>
              The Services are hosted and operated in the United States (“U.S.”) through Trustle and its service
              providers, and if you do not reside in the U.S., laws in the U.S. may differ from the laws where you
              reside. By using the Services, you acknowledge that any Personal Data about you, regardless of whether
              provided by you or obtained from a third party, is being provided to Trustle in the U.S. and will be
              hosted on U.S. servers, and you authorize Trustle to transfer, store and process your information to and
              in the U.S., and possibly other countries. You hereby consent to the transfer of your data to the U.S.
              pursuant to: (i) a data processing agreement incorporating standard data protection clauses promulgated by
              the European Commission, a copy of which can be obtained at <span>https://eur-lex.europa.eu/legal-</span>{' '}
              <span>content/EN/TXT/?uri=celex%3A32010D0087</span>, (ii) binding corporate rules for data protection that
              align with the GDPR’s requirements, or (iii) adherence to an industry- or technology-specific approved
              code of conduct blessed by the European Commission.
            </p>
            <br></br>
            <h2 className="my-2 font-bold underline" id="ChangestothisPrivacyPolicy">
              Changes to this Privacy Policy
            </h2>
            <p>
              We’re constantly trying to improve our Services, so we may need to change this Privacy Policy from time to
              time, but we will alert you to any such changes by placing a notice on the Trustle website, by sending you
              an email and/or by some other means. Please note that if you’ve opted not to receive legal notice emails
              from us (or you haven’t provided us with your email address), those legal notices will still govern your
              use of the Services, and you are still responsible for reading and understanding them. If you use the
              Services after any changes to the Privacy Policy have been posted, that means you agree to all of the
              changes. Use of information we collect is subject to the Privacy Policy in effect at the time such
              information is collected.
            </p>
            <br></br>
            <h2 className="my-2 font-bold underline" id="googleAPIServicesUserDataPolicy">
              Google API Services User Data Policy
            </h2>
            <p>
              Trustle’s use and transfer to any other app of information received from Google APIs will adhere to{' '}
              <a
                href="https://developers.google.com/terms/api-services-user-data-policy"
                target="_blank"
                className="font-bold"
                rel="noreferrer"
              >
                {' '}
                Google API Services User Data Policy
              </a>
              , including the Limited Use requirements.
            </p>
            <br></br>
            <h2 className="my-2 font-bold underline" id="contacInformation">
              Contact Information:
            </h2>
            <p>
              If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your
              Personal Data or your choices and rights regarding such collection and use, please do not hesitate to
              contact us at:
            </p>
            <ul className="mb-20 ml-10 list-disc">
              <li>trustle.com</li>
              <li>
                <a href="mailto:legal@trustle.com">legal@trustle.com</a>
              </li>
              <li>1250 Newell Ave Suite I314, Walnut Creek, CA 94596</li>
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Privacy;
